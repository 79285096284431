export default {
  // Endpoints
  loginAiopsEndpoint: 'http://20.199.81.9:8080/auth/realms/AIOPS/protocol/openid-connect/token',
  loginEndpoint: '/jwt/login',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // params to login
  clientId: 'aiops-service',
  grant_type: 'password',
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',
  contentType: 'Content-Type',
  content_type: 'application/x-www-form-urlencoded',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
